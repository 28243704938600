.toast-message > div {
    display: flex;
    width: 100%;
}

.toast-message {
    color: black;
}

.toast-message > button {
    width: fit-content;
    padding: 5px 10px;
}