* {
    margin: 0;
    padding: 0;
}

body {
    margin: 0;
    font-family: 'Lusitana', sans-serif;
}

.login-button {
    cursor: pointer;
}

.login-1 {
    text-decoration: none;
}


.login-button-area {
    display: flex;
    justify-content: center;
    align-items: center;
}


input {
    width: 30rem;
    height: 3rem;
    margin: 2vh auto ;
    border-radius: 0.5rem;
    border-style: none;
    font-size: 1.5rem;
    font-family: 'Lusitana', sans-serif;
    padding: 0.2rem 1rem 0.2rem 1rem;
}

input:focus {
    border: none;
    outline: none;
}

.white-div {
    position: fixed;
    width: 25%;
    height: 100%;
    background-color: #F3F7FD;
}

.yellow-div {
    position: fixed;
    text-align: center;
    right: 0;
    width: 75%;
    height: 100%;
    background-color: #F9D422;
}

.login-gif {
    position: fixed;
    width: 45%;
    top: 10%;
}

.login-text {
    color: black;
    margin: 10% auto;
    text-align: center;
    font-size: 3rem;
}

.username-div {
    display: inline-block;
    text-align: left;
    margin-top: 5vh;
}


p {
    text-align: right;
    font-size: 1.5rem;
    margin-bottom: 2vh;
    font-family: 'Lusitana', sans-serif;
}

label {
    font-size: 2rem;
    font-family: 'Lusitana', sans-serif;
}

button {
    display: block;
    margin: 0 auto 0 auto;
    width: 15rem;
    height: 3.5rem;
    background-color: white;
    border-style: none;
    font-size: 1.5rem;
    font-family: 'Lusitana', sans-serif;
}

h4 {
    display: inline-block;
    font-size: 1.5rem;
    font-weight: 500;
}

.signup a {
    color: black;
}

.account-text {
    display: flex;
    padding: 5vh 0;
    align-items: center;
    justify-content: center;
}