body {
    margin: 0;
    font-family: 'Lusitana', sans-serif;
}

.username-div > input:focus {
    border: none;
    outline: none;
}

.white-div {
    position: fixed;
    width: 25%;
    height: 100%;
    background-color: #F3F7FD;
}

.yellow-div {
    position: fixed;
    text-align: center;
    right: 0;
    width: 75%;
    height: 100%;
    background-color: #F9D422;
    
}

.login-gif {
    position: fixed;
    width: 45%;
    top: 10%;
}

.login-text1 {
    color: black;
    text-align: center;
    font-size: 3rem;
    margin: 2% 0 3% 0;
}

.username-div1 {
    display: inline-block;
    text-align: left;
    height: 80vh;
}


.username-div1> form > input {
    width: 30rem;
    height: 2rem;
    border-radius: 0.5rem;
    border-style: none;
    font-size: 1.5rem;
    font-family: 'Lusitana', sans-serif;
    padding: 0.2rem 1rem 0.2rem 1rem;
}

/* p {
    text-align: right;
    font-size: 1.5rem;
    margin-top: -1rem;
    font-family: 'Lusitana', sans-serif;
} */

.username-div1> form > label {
    font-size: 2rem;
    font-family: 'Lusitana', sans-serif;
}

.username-div1>form > button {
    display: block;
    margin: 0 auto 0 auto;
    width: 15rem;
    height: 3rem;
    background-color: white;
    border-style: none;
    font-size: 1.5rem;
    font-family: 'Lusitana', sans-serif;
}



.signup-button-area {
    display: flex;
    align-items: center;
    justify-content: center;
}

.signup a {
    color: black;
}

.signup-aTag {
    text-decoration: none;
}

.account-text {
    text-align: center;
}

@media only screen and (max-width: 1024px) {
    .username-div {
        margin-top: 0vh;
    }
}